import logo from './logo.svg';
import './App.css';

import Layout from './layout/layout';
import AppRouting from './routes/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { Helmet } from "react-helmet"
import { jwtDecode } from 'jwt-decode';
import AppContext from 'context/appContext';
import { socialSignin } from 'services/services';
import axios from 'axios';
import { socket } from 'utils/socket';
import { addUser, receiveMessage } from 'utils/socketEvents';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
function App() {
  const location = useLocation()
  const context = useContext(AppContext)
  const navigate = useNavigate()
    const stripe = loadStripe('pk_test_51OMXr1K3LYq5NUZejDYONTz9dksNwopf365dDxjtyd9WPPpQE3GMFKx6amyO6XwOKthGX4apD3wqfNJtOPXYtP1e00qxGUmXcU')
   // const stripe = loadStripe('pk_live_51OMXr1K3LYq5NUZeN6GorrAric26MFsyBpbG31PRWSQr19fxKTMwDc6IQ9TRoYjBkHRVDQ3tppWjFM3MsZDK99Vo00vAiIeo83')

  
//   const options = {
//     // passing the client secret obtained from the server
//     clientSecret: 'sk_test_51OMXr1K3LYq5NUZert9uLvlIv7pc2rJWp4ketwcEcMpr0UQr723rlRnk97Ml54TWHF2oE3oGvmvOMLAEYaDb5OgE00T1nB12r7',
// };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  


  const socialLogin = (res) => {
    const data = {
      "email": res.email,
      "socialId": res.sub,
      "type":"user"
    }
    socialSignin(data).then(res => {
      if (!res.data.data.isFirstTiime) {
        context.setToken(res.data.data.token)
        context.setUserId(res.data.data._id)
        context.setHavePaymentMethod(res.data.data.have_default_payment_method)
        localStorage.setItem('token', res.data.data.token)
        localStorage.setItem('userId',res.data.data._id)
        localStorage.setItem('have_payment_method',res.data.data.have_default_payment_method)
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`;
        toast.success("logged in successfully.")
        navigate('/home')
      }
      else{
        // context.setSocialData(res)
        context.setIsSocial(true)
        navigate('/signup')
      }
    }).catch(err => {
          toast.error(err.response.data.message)
    })
  }

  const handleCredentialResponse = (data) => {
    // console.log(jwtDecode(data.credential))
    let signinData = jwtDecode(data.credential)
    context.setSocialData(signinData)
    socialLogin(signinData)
  }

  // window.onload = () => {
  //   window.google?.accounts.id.initialize({
  //     client_id: '683255337967-4t8m5vi3hj6jukkbtr6g6bjq0pi16f4g.apps.googleusercontent.com',
  //     callback: handleCredentialResponse
  //   });
  //   window.google?.accounts.id.renderButton(
  //     context.googleRef.current,
  //     { theme: "outline", size: "large" }  // customization attributes
  //   );
  //   window.google?.accounts.id.prompt();
  // }

  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: '683255337967-4t8m5vi3hj6jukkbtr6g6bjq0pi16f4g.apps.googleusercontent.com',
      callback: handleCredentialResponse
    });
    window.google?.accounts.id.renderButton(
     context.googleRef.current,
      { theme: "outline", size: "large" }  // customization attributes
    );
    // window.google?.accounts.id.prompt();
  }, [location,context.googleRef.current]);
 
  socket.on('connect', ()=>{
    console.log('conected')
    let userId = localStorage.getItem('userId')
  let data =   addUser(userId)
    // receiveMessage()
  })




  return (
    <>
     <Helmet>
        <script src="https://accounts.google.com/gsi/client" async></script>
      </Helmet >
      <Layout>
        <Elements stripe={stripe} >
        <AppRouting />
        </Elements>
      </Layout>
      <ToastContainer theme='dark' />
    </>
  );
}

export default App;
