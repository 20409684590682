import { useEffect, useRef, useState } from "react";
import AppContext from "./appContext";
import axios from "axios";

const AppState = ({ children }) => {
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [havePaymentMethod, setHavePaymentMethod] = useState("");
  const [userType, setUserType] = useState("");
  const [assigned, setAssigned] = useState("");
  const [jobCreateSuccess, setJobCreateSuccess] = useState("");
  let googleRef = useRef();
  // const img_uri = "http://localhost:5003";
   const img_uri = "https://mobilenotary-api.alcax.com";
   // const img_uri = "https://api.mobilenotaryx.ai/";
  const [socialData, setSocialData] = useState(null);
  const [isSocial, setIsSocial] = useState(false);
  const [socialType, setSocialType] = useState("google");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [languages, setLanguages] = useState([]);
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    const guestToken = localStorage.getItem("guestToken");
    if (token || guestToken) {
      const authToken = token ? `Bearer ${token}` : `Bearer ${guestToken}`;
      axios.defaults.headers.common["Authorization"] = authToken;
      setToken(localStorage.getItem("token"));
      setUserId(localStorage.getItem("userId"));
      setStates(JSON.parse(localStorage.getItem("states")));
      setCities(JSON.parse(localStorage.getItem("cities")));
      setLanguages(JSON.parse(localStorage.getItem("languages")));
      setHavePaymentMethod(localStorage.getItem("have_payment_method"));
      setUserType(localStorage.getItem("userType"))
      setJobCreateSuccess(JSON.parse(localStorage.getItem("jobCreateSuccess")))
    }
  }, []);

  const values = {
    token,
    setToken,
    userId,
    setUserId,
    havePaymentMethod,
    setHavePaymentMethod,
    userType,
    setUserType,
    assigned,
    setAssigned,
    googleRef,
    socialData,
    setSocialData,
    isSocial,
    setIsSocial,
    socialType,
    setSocialType,
    states,
    setStates,
    cities,
    setCities,
    languages,
    setLanguages,
    jobCreateSuccess,
    setJobCreateSuccess,
    img_uri,
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppState;
