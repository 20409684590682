import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { changePassword } from "services/services";
import { BiSolidHide,BiShow } from "react-icons/bi";

const ChangePassword = () => {
    const {register,handleSubmit, formState:{errors},watch,reset}= useForm()
    const [showOldPassword, setShowOlPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);

    const updatePassword = (data)=>{
        delete data['confirmPassword']
        changePassword(data).then(response=>{
            if(response.status==200){
                reset()
                toast.success(response.data.message)
            }
        }).catch(err=>{
            toast.error(err.response.data.message)
        })
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
const toggleOlPasswordVisibility = () =>{
    setShowOlPassword(!showOldPassword)
}
const toggleConPasswordVisibility = () =>{
    setShowConPassword(!showConPassword)
}

    return (
        <>
            <div class="tab-pane fade" id="v-4">
                <div class="card-header border-0 theme-color py-3">
                    <span class="text-white fw-bolder ms-2">Change Password</span>
                </div>
                <form class="contact-form p-4" onSubmit={handleSubmit(updatePassword)}>
                    <div class="mb-4 position-relative">
                        <label for="old-password" class="form-label">Old Password</label>
                        <input type={showOldPassword ? "text" : "password"} class="form-control" name="old-password" {...register('oldPassword',{required:"Please enter old password."})}/>
                        <div className='password-hide-show' onClick={toggleOlPasswordVisibility}>{showOldPassword ? <BiShow /> : <BiSolidHide />} </div>
                        {errors.oldPassword && <span className='err-msg'>{errors.oldPassword.message}</span>}
                    </div>
                    <div class="mb-4 position-relative">
                        <label for="new-password" class="form-label">New Password</label>
                        <input type={showPassword ? "text" : "password"} class="form-control" name="new-password" {...register('password',{required:"Please enter new password."})}/>
                        <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                        {errors.password && <span className='err-msg'>{errors.password.message}</span>}
                    </div>
                    <div class="mb-4 position-relative">
                        <label for="confirm" class="form-label">Confirm New Password</label>
                        <input type={showConPassword ? "text" : "password"} class="form-control" name="confirm" {...register('confirmPassword',{required:"Please enter confirm password.",validate:value=>value===watch('password')||"Password doesn;t match."})}/>
                        <div className='password-hide-show' onClick={toggleConPasswordVisibility}>{showConPassword ? <BiShow /> : <BiSolidHide />} </div>
                        {errors.confirmPassword && <span className='err-msg'>{errors.confirmPassword.message}</span>}
                    </div>
                    <button type="submit" className="btn btn-book py-3 px-md-5">Update</button>
                </form>
            </div>
        </>
    );
}

export default ChangePassword;