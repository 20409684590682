import Icon5 from "assests/images/icon-5.png";
import Calender from "assests/images/calendar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { createJob, getFlatprices, getService, getAddedCards,guestUserCreate } from "services/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import AppContext from "context/appContext";
import GuestUser from "./GuestUser";
import axios from 'axios';

const RealEstateTransaction = ({ state }) => {
  const RealEstateServiceTypes = [
    "Seller Side Only",
    "Purchase Side Only",
    "Purchase and Sale Package",
    "Refinance",
    "HELOC",
    "Deed",
  ];
  const context = useContext(AppContext);
  let {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm();
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateIndex, setSelectedStateIndex] = useState(-1);
  const [flatPriceData, setFlatPriceData] = useState([]);
  const [servicePriceData, setServicePriceData] = useState([]);
  const [cards, setCards] = useState([]);
  const [travelFee, setTravelFee] = useState(0);
  const [flatPrice, setFlatPrice] = useState(0);
  const [showGuestUser, setShowGuestUser] = useState(false)
  const id = state._id;
  const stateVal = watch("state");

  const getCards = () => {
    getAddedCards()
      .then((response) => {
        if (response.status == 200) {
          setCards(response?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    let tokenSave = localStorage.getItem('token')
    let guestToken = localStorage.getItem('guestToken')
    if(tokenSave || guestToken){
      let token =tokenSave?tokenSave:guestToken;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      getCards();
    }
  }, []);

  const subnitForm = (data) => {
    const location = {
      place_id: data.location.place_id,
      lat: data.location.geometry.location.lat(),
      long: data.location.geometry.location.lng(),
      name: data.location.formatted_address,
    };
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('guestUser');
    const form_data = {
      name: data.name,
      email: data.email,
      service: state._id,
      loc: JSON.stringify(location),
      schedule_date: state.selectedDate,
      job_type: state.jobType,
      state: data.state,
      city: data.city,
      language: data.language,
      are_you_signer: data.is_signer,
      relation_with_signer: data.relation_with_signer,
      type_of_real_estate_transaction: data.type_real_estate_transaction,
      reviewed_our_pricing_structure: data.reviewed_pricing_structure,
      required_witness: data.required_witness,
      service_charge_per_hour: state.price,
      role: token == null ? 'guest' : ''
    };
    if ((token !== null && cards.length == 0 && context.userType == "individual") || (token == null && userEmail != data?.email)) {
      if(token == null){
        let userData = {
        name: data.name,
        email: data.email,
         role: 'guest'
        }
        guestUserCreate(userData)
        .then((response) => {
         localStorage.setItem('guestToken', response?.data?.token)
         axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.token}`;
        })
        .catch((err) => {
          console.log(err);

        });

      }

      context.setJobCreateSuccess(form_data)
      localStorage.setItem('jobCreateSuccess', JSON.stringify(form_data))
      localStorage.setItem('guestUser', data.email)
      setTimeout(() => {
        navigate("/add-card")
      }, 1000)
    } 
    else if(token == null && cards.length == 0){
      setTimeout(() => {
        navigate("/add-card")
      }, 1000)
    }
    else {
      createJob(form_data)
        .then((response) => {
          if (response.status == 201) {
            toast.success("job created successfully.");
            navigate("/services");
          }
        })
        .catch((err) => {
          console.log(err);
          
          if (err.response.data.message == "this email already used") {
            setShowGuestUser(true)
          } else {
            toast.error(err.response.data.message);
          }
        });
    }

  };
  

  const onChangeState = (value) => {
    let isStateHavePrice = flatPriceData.filter(
      (item) => item.state.name == value
    );
    let isStateHaveTypePrice = servicePriceData.filter(
      (item) => item.state == value
    );

    if (isStateHavePrice.length !== 0 && isStateHaveTypePrice.length !== 0) {
      return true;
    } else {
      setSelectedState("");
      return "Service is not available for this state.";
    }
  };

  const onChangeCity = (value) => {
    let cityArr = flatPriceData.filter(
      (item) => item.state.name == selectedState
    )[0].cities;
    let isCityHavePrice = cityArr.filter((item) => item?.name == value);
    if (isCityHavePrice?.length == 0) {
      return "Service is not available for this city.";
    } else {
      return true;
    }
  };
  const onChangeType = (value) => {
    if (selectedStateIndex === -1) {
      return "Service is not available for this type.";
    }
    let priceArray = servicePriceData[selectedStateIndex]?.realEstatePrices;
    const isTypePrice = priceArray?.filter((item) => item.type === value);
    if (isTypePrice?.length !== 0) {
      return true;
    } else {
      return "Service is not available for this type.";
    }
  };

  const handleTravelFee = (event) => {
    let city = flatPriceData
      .filter((item) => item.state.name == selectedState)[0]
      ?.cities?.filter((item) => item?.name == event.target.value)[0];
    setTravelFee(city?.price || 0);
  };

  useEffect(() => {
    setValue("type_real_estate_transaction", "");
  }, [stateVal]);

  const handleNotaryFee = (event) => {
    setSelectedState(event.target.value);
    let index = servicePriceData?.findIndex(
      (item) => item.state == event.target.value
    );
    setSelectedStateIndex(index);
    setFlatPrice(0);
    setTravelFee(0);
  };

  const handleServiceType = (event) => {
    const { value } = event.target;
    if (selectedStateIndex === -1) {
      setFlatPrice(0);
    } else {
      let priceArray = servicePriceData[selectedStateIndex]?.realEstatePrices;
      const myObj = priceArray?.find((item) => item.type === value);
      setFlatPrice(myObj?.price || 0);
    }
  };

  useEffect(() => {
    const getFlatPricesList = () => {
      getFlatprices().then((res) => {
        if (res.status == 200) {
          setFlatPriceData(res.data.list);
        }
      });
    };
    const getServicePriceByState = () => {
      getService(id).then((res) => {
        if (res.status === 200) {
          setServicePriceData(res.data.list.states);
        }
      });
    };
    getFlatPricesList();
    getServicePriceByState();
  }, []);

  const handleClose = () => {
    setShowGuestUser(false)
  }


  return (
    <>
      <div class="bg-service">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">Fill Details</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="#" class="text-decoration-none text-white fw-700">
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Fill Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {showGuestUser && <GuestUser showGuestUser={showGuestUser} handleClose={handleClose} />}
      {/* <!-- Form --> */}
      <div class="container text-black mt-5">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <img src={Icon5} alt="images" />
              <h4 class="fw-900">Real Estate Transactions</h4>
              <div>
                <img src={Calender} alt="caldendar" />
                {moment(state.selectedDate).format("h:mm A, dddd,MMMM DD,YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Details --> */}
      <form onSubmit={handleSubmit(subnitForm)} class="service-form">
        <div class="container mt-5 text-black align-items-center">
          <div class="row">
            <div class="col-md-12">
              <h4 class="fw-900 mb-4">Enter Details</h4>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="name" class="form-label ">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  {...register("name", { required: "Name is required." })}
                />
                {errors.name && (
                  <span className="err-msg">{errors.name.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="email" class="form-label ">
                  Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email address.",
                    },
                  })}
                />
                {errors.email && (
                  <span className="err-msg">{errors.email.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Please select state
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("state", {
                    required: "Please select the state",
                    validate: onChangeState,
                    onChange: (e) => handleNotaryFee(e),
                  })}
                >
                  <option value="">Select State</option>
                  {context.states &&
                    context.states.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}{" "}
                      </option>
                    ))}
                </select>
                {errors.state && (
                  <span className="err-msg">{errors.state.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Please select City
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("city", {
                    required: "Please select the city",
                    validate: onChangeCity,
                    onChange: (e) => handleTravelFee(e),
                  })}
                >
                  <option value="">Select City</option>
                  {context.cities &&
                    context.cities[selectedState]?.map(
                      (city) => (
                        <option key={city} value={city}>
                          {city}{" "}
                        </option>
                      )
                     
                    )}
                </select>
                {errors.city && (
                  <span className="err-msg">{errors.city.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Address the Notary will be traveling to?
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <Controller
                  control={control}
                  name="location"
                  rules={{ required: "Please select address." }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      class="form-control"
                      apiKey="AIzaSyDXHHQVzMU2AVAjN99gENu43J810uCCBwc"
                      options={{
                        types: [],
                        componentRestrictions: { country: "us" },
                      }}
                      onPlaceSelected={(place) => {
                        onChange(place);
                      }}
                    />
                  )}
                />
                {errors.location && (
                  <span className="err-msg">{errors.location.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <label for="singer" class="form-label d-block ">
                  Are you the signer?
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="yes"
                    {...register("is_signer", {
                      required: "Please select one of the option.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="no"
                    {...register("is_signer", {
                      required: "Please select one of the option.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    No
                  </label>
                </div>
                {errors.is_signer && (
                  <span className="err-msg">{errors.is_signer.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="singer_rel" class="form-label ">
                  If no, please state your relationship to the signer?
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="singer_rel"
                  {...register("relation_with_signer")}
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Please select type
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("type_real_estate_transaction", {
                    required: "Please select the type",
                    validate: onChangeType,
                    onChange: (e) => handleServiceType(e),
                  })}
                >
                  <option value="">Select Type</option>
                  {RealEstateServiceTypes?.map((type, index) => (
                    <option key={type + index} value={type}>
                      {type}{" "}
                    </option>
                  ))}
                </select>
                {errors.type_real_estate_transaction && (
                  <span className="err-msg">
                    {errors.type_real_estate_transaction.message}
                  </span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="singer" class="form-label d-block ">
                  Have you reviewed our pricing and cancellation disclaimers
                  structure on the website?
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="yes"
                    {...register("reviewed_pricing_structure", {
                      required: "Please one of the options.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="no"
                    {...register("reviewed_pricing_structure", {
                      required: "Please one of the options.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio4">
                    No
                  </label>
                </div>
                {errors.reviewed_pricing_structure && (
                  <span className="err-msg">
                    {errors.reviewed_pricing_structure.message}
                  </span>
                )}
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  What language is the document(s) in?
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("language", {
                    required: "Please select the language.",
                  })}
                >
                  <option value="">Select Language</option>
                  {context.languages &&
                    context.languages.map((language) => (
                      <option key={language.name} value={language.name}>
                        {language.name}{" "}
                      </option>
                    ))}
                </select>
                {errors.language && (
                  <span className="err-msg">{errors.language.message}</span>
                )}
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="add_documents" class="form-label ">
                  Does the document being notarized require additional witnesses
                  not including the notary?{" "}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="add_documents"
                  {...register("required_witness", {
                    required: "This field is required.",
                  })}
                />
                {errors.required_witness && (
                  <span className="err-msg">
                    {errors.required_witness.message}
                  </span>
                )}
              </div>
            </div>
            {/*<div class="col-md-6">
                        <div class="mb-4 pb-2">
                            <label for="message" class="form-label ">Send text messages to </label>
                            <input type="text" class="form-control" id="message" />
                        </div>
                    </div> */}
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                {...register("travelFeeCheckbox", {
                  required: "Please accept this.",
                })}
              />
              <label class="form-check-label" for="exampleCheck1">
                ${travelFee} will be pre-authorized for travel fee and will be
                charged in addition to ${flatPrice} based on the type of real
                estate transaction once completed.{" "}
                <Link style={{ color: "aqua" }} to="/pricing">
                  See page for more details
                </Link>
              </label>
            </div>
            {errors.travelFeeCheckbox && (
              <span className="err-msg">
                {errors.travelFeeCheckbox.message}
              </span>
            )}
          </div>
          <div class="col-xl-3 col-md-4">
            <button type="submit" className="btn btn-continue w-100 mt-3">
              Schedule Event
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RealEstateTransaction;
