import React from "react";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "../assests/images/fav-icon.png"; // Make sure the path is correct

const buttonWidth = {
    width: "100%",
};

const GuestUser = (props) => {
    const { showGuestUser, handleClose } = props;
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate("/login");
        handleClose();
    };

    return (
        <Modal show={showGuestUser} onHide={handleClose} centered size="md">
            <Modal.Body className="px-4 py-4 text-center">
                <Modal.Header className="border-0 my-0 pt-0 pb-2 close-popup">
                    <div className="text-center mx-auto d-block">
                        <Image src={Icon} alt="Icon" />
                        <Modal.Title className="mt-3 mb-2">
                            <h5 className="fw-bolder">
                                Your booking is successfully created.
                            </h5>
                            <p style={{ fontSize: "15px" }}>
                                To check the details you can log in with the password sent to your email.
                            </p>
                        </Modal.Title>
                    </div>
                    {/* Custom close button */}
                    <button
                        type="button"
                        className="btn-close custom-close-button close-popup"
                        aria-label="Close"
                        onClick={handleClose}
                    ></button>
                </Modal.Header>

                <form className="form-login">
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-service py-2 fw-bolder update-class"
                            style={buttonWidth}
                            onClick={handleSubmit}
                        >
                            OK
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default GuestUser;
