import AppContext from "context/appContext";
import { useContext, useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getProfile, updateProfileImage } from "services/services";

const MyAccountSidebar = () => {
  const context = useContext(AppContext);
  let navigate = useNavigate();
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [image, setImage] = useState("");

  const imageObj = (e) => {
    setImage(e.target.files[0]);
    let file_url = URL.createObjectURL(e.target.files[0]);

    setAvatar(file_url);
  };

  const updateImage = () => {
    const data = new FormData();
    data.append("image", image);
    updateProfileImage(data)
      .then((res) => {
        if (res.status == 200) {
          toast.success("Avatar updated successfully.");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  //logout function
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("guestToken");
    localStorage.removeItem("guestUser");
    localStorage.removeItem("userType");
    context.setToken("");
    navigate("/login");
  };
  // useEffect(() => {
  //     // Access the hash fragment and scroll to the corresponding section
  //     const hashFragment = location.hash;
  //     console.log(hashFragment);
  //     if (hashFragment) {
  //       const targetElement = document.querySelector(hashFragment);
  //       console.log(targetElement);
  //       if (targetElement) {
  //         targetElement.scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }
  //   }, []);
  useEffect(() => {
    const myProfile = () => {
      getProfile()
        .then((response) => {
          if (response.status == 200) {
            console.log("jj", response.data.data);
            setProfileData(response.data.data);
            response.data.data.avatar &&
              setAvatar(context.img_uri + response.data.data.avatar);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    };
    myProfile();
  }, []);
  console.log("h", profileData?.socilaId);

  return (
    <>
      <div class="col-lg-4 mb-3 mb-lg-0">
        <div class="card border-0">
          <div class="card-body">
            <p class="text-uppercase small">My Account</p>
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div class="avatar-upload text-center">
                <div class="avatar-edit">
                  <input
                    type="file"
                    id="ProfilePictureWeb"
                    name="ProfilePictureWeb"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => imageObj(e)}
                  />
                  <label for="ProfilePictureWeb"></label>
                </div>
                <div class="avatar-preview">
                  <img
                    src={avatar || require("../assests/images/user.png")}
                    class="img-fluid"
                  />
                </div>
              </div>
              <button class="btn btn-book py-3 px-md-5" onClick={updateImage}>
                Update profile image
              </button>
              <div
                class="nav-link active px-0 pb-0"
                data-bs-toggle="pill"
                data-bs-target="#v-1"
                type="button"
              >
                <ol class="list-group">
                  <li class=" border-0  px-2 list-group-item d-flex justify-content-between align-items-center">
                    <div class="me-auto">
                      <div class="ft">Edit Profile</div>
                    </div>
                    <FaArrowRight />
                  </li>
                </ol>
              </div>
              {profileData && profileData.socialId == "" && (
                <div
                  class="nav-link  px-0 pb-0"
                  data-bs-toggle="pill"
                  data-bs-target="#v-4"
                  type="button"
                >
                  <ol class="list-group">
                    <li class="border-0 px-2 list-group-item d-flex justify-content-between align-items-center">
                      <div class="me-auto">
                        <div class="ft">Change Password</div>
                      </div>
                      <FaArrowRight />
                    </li>
                  </ol>
                </div>
              )}
              <div
                class="nav-link  px-0 pb-0"
                data-bs-toggle="pill"
                data-bs-target="#v-5"
                type="button"
              >
                <ol class="list-group">
                  <li class="border-0 px-2 list-group-item d-flex justify-content-between align-items-center">
                    <div class="me-auto">
                      <div class="ft">My Bookings</div>
                    </div>
                    <FaArrowRight />
                  </li>
                </ol>
              </div>
              <div
                class="nav-link  px-0 pb-0"
                data-bs-toggle="pill"
                data-bs-target="#v-6"
                type="button"
              >
                <ol class="list-group">
                  <li class="border-0 px-2 list-group-item d-flex justify-content-between align-items-center">
                    <div class="me-auto">
                      <div class="ft">My Cards</div>
                    </div>
                    <FaArrowRight />
                  </li>
                </ol>
              </div>
              {context.userType=="business" &&
                <div
                class="nav-link  px-0 pb-0"
                data-bs-toggle="pill"
                data-bs-target="#v-7"
                type="button"
              >
                <ol class="list-group">
                  <li class="border-0 px-2 list-group-item d-flex justify-content-between align-items-center">
                    <div class="me-auto">
                      <div class="ft">Choose Preferred</div>
                    </div>
                    <FaArrowRight />
                  </li>
                </ol>
              </div>
              }
            
              {/* <div class="nav-link  px-0 pb-0" data-bs-toggle="pill" data-bs-target="#v-7" type="button">
                                <ol class="list-group">
                                    <li class="border-0 px-2 list-group-item d-flex justify-content-between align-items-center">
                                        <div class="me-auto">
                                            <div class="ft">Add Card</div>
                                        </div>
                                        <FaArrowRight />
                                    </li>
                                </ol>
                            </div> */}
              <div
                class="px-2 my-3"
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              >
                <div class="ft">Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccountSidebar;
